export const flexMessage = {
  type: "flex",
  altText: "รับเลย 50฿ แค่สมัคร",
  contents: {
    "type": "bubble",
    "size": "giga",
    "body": {
      "type": "box",
      "layout": "vertical",
      "contents": [
        {
          "type": "image",
          "url": "https://img2.pic.in.th/pic/LINE_ALBUM_NFTMETA__6.jpeg",
          "size": "full",
          "aspectMode": "fit"
        }
      ],
      "paddingAll": "0px",
      "action": {
        "type": "uri",
        "label": "line",
        "uri": "https://lin.ee/himA80c"
      }
    }
  }
}